@mixin sidebar-background-color($background-color, $font-color) {
    .nav {
        .nav-item {
            .nav-link {
                color: $font-color;
            }
            i {
                color: rgba($font-color, .8);
            }
            &.active,
            &:hover {
                [data-toggle="collapse"] {
                    color: $font-color;
                    i {
                        color: rgba($font-color, .8);
                    }
                }
            }
        }
    }
    .user {
        a {
            color: $font-color;
        }
    }
    .simple-text {
        color: $font-color;
    }
    .sidebar-background:after {
        background: $background-color;
        opacity: .8;
    }
}

@mixin sidebar-active-color($font-color) {
    .nav {
        .nav-item {
            &.active>a:not([data-toggle="collapse"]) {
                color: $font-color;
                opacity: 1;
                @include shadow-big-color($font-color);
                i {
                    color: rgba($font-color, .8);
                }
            }
        }
    }
}

@mixin set-background-color-button($color) {
    li.active>a {
        background: linear-gradient(60deg, $color, transparent);
        opacity: .5;
        box-shadow: none !important;
        // @include shadow-big-color($color);
    }
    li.active>a:before {
        content: '';
        height: 25px;
        position: absolute;
        background: #CFD328;
        width: 7px;
        left: 12px;
    }
}